import {IGallerySantaProps, IGalleryStyleParams} from '../types/galleryTypes';
import {IPropsInjectedByViewerScript} from '../types/sliderGalleryTypes';
import {IHostProps} from '@wix/native-components-infra/dist/es/src/types/types';

type ProvidedGlobalPropNames =
  | 'allowFreeProducts'
  | 'addedToCartStatus'
  | 'experiments'
  | 'getCategoryProducts'
  | 'handleAddToCart'
  | 'handleProductItemClick'
  | 'isFirstPage'
  | 'isInteractive'
  | 'isLiveSiteMode'
  | 'isMobile'
  | 'isRTL'
  | 'mainCollectionId'
  | 'openQuickView'
  | 'productsManifest'
  | 'products'
  | 'shouldShowAddToCartSuccessAnimation'
  | 'showTitle'
  | 'textsMap'
  | 'totalProducts'
  | 'updateAddToCartStatus';

export interface ISliderGlobalProps {
  globals?: Pick<IPropsInjectedByViewerScript & IGallerySantaProps, ProvidedGlobalPropNames> & {
    styleParams: IGalleryStyleParams;
  } & Pick<IHostProps, 'updateLayout' | 'dimensions' | 'appLoadBI'>;
}

export const sliderGlobalPropsStrategy = (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
  const {
    allowFreeProducts,
    addedToCartStatus,
    experiments,
    getCategoryProducts,
    handleAddToCart,
    handleProductItemClick,
    isFirstPage,
    isInteractive,
    isLiveSiteMode,
    isMobile,
    isRTL,
    mainCollectionId,
    openQuickView,
    productsManifest,
    products,
    shouldShowAddToCartSuccessAnimation,
    showTitle,
    style,
    textsMap,
    totalProducts,
    updateAddToCartStatus,
    ...locals
  } = props;

  const {appLoadBI, dimensions, updateLayout} = props.host;
  return {
    globals: {
      allowFreeProducts,
      addedToCartStatus,
      appLoadBI,
      dimensions,
      experiments,
      getCategoryProducts,
      handleAddToCart,
      handleProductItemClick,
      isFirstPage,
      isInteractive,
      isLiveSiteMode,
      isMobile,
      isRTL,
      mainCollectionId,
      openQuickView,
      productsManifest,
      products,
      showTitle,
      shouldShowAddToCartSuccessAnimation,
      styleParams: style.styleParams,
      textsMap,
      totalProducts,
      updateAddToCartStatus,
      updateLayout,
    },
    locals,
  };
};
